
import { defineComponent } from 'vue';
import KTUserMenu from '@/views/new-design/layout/Header/UserMenu.vue';
import Avatar from '@/components/avatars/Avatar.vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/enums/StoreEnums';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { User } from '@/models/UserModel';
import { acronym } from '@/utils/text';

export default defineComponent({
  name: 'topbar',
  components: {
    Avatar,
    KTUserMenu,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {
        return {} as unknown as User;
      },
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      profilePicture: AuthGetters.GET_AUTH_USER_PROFILE_PICTURE,
      defaultBGColor: Getters.GET_DEFAULT_BACKGROUND_COLOR,
      defaultTextColor: Getters.GET_DEFAULT_TEXT_COLOR,
    }),
    backgroundColor() {
      return this.currentUser.business_color ?? this.defaultBGColor;
    },
    textColor() {
      return this.currentUser.business_text_color ?? this.defaultTextColor;
    },
    acronym() {
      if (
        this.currentUser.first_name === '' ||
        this.currentUser.last_name === ''
      )
        return acronym(this.currentUser.email);

      return acronym(
        `${this.currentUser.first_name} ${this.currentUser.last_name}`
      );
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    close(e) {
      let el = this.$refs.userMenu;
      let target = e.target;
      if (el !== target && !(el as any).contains(target)) {
        this.showMenu = false;
      }
    },
  },
});
