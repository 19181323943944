
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import {
  Actions as AuthActions,
  Getters as AuthGetters,
} from '@/store/enums/AuthEnums';
import Avatar from '@/components/avatars/Avatar.vue';
import { User } from '@/models/UserModel';
import { Getters } from '@/store/enums/StoreEnums';

export default defineComponent({
  name: 'kt-user-menu',
  components: {
    Avatar,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {
        return {} as unknown as User;
      },
    },
    acronym: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    show: {
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      profilePicture: AuthGetters.GET_AUTH_USER_PROFILE_PICTURE,
      defaultBGColor: Getters.GET_DEFAULT_BACKGROUND_COLOR,
      defaultTextColor: Getters.GET_DEFAULT_TEXT_COLOR,
    }),
    backgroundColor() {
      return this.currentUser?.business_color ?? this.defaultBGColor;
    },
    textColor() {
      return this.currentUser?.business_text_color ?? this.defaultTextColor;
    },
    userEmail() {
      return this.currentUser?.email ? this.currentUser.email : '';
    },
    userName() {
      if (this.currentUser?.first_name && this.currentUser?.last_name) {
        return `${this.currentUser?.first_name} ${this.currentUser?.last_name}`;
      }

      return this.currentUser?.email;
    },
    userRoles() {
      return this.currentUser?.roles ? this.currentUser.roles[0] : '';
    },
    mailTo() {
      return `mailto:${this.currentUser?.email}`;
    },
  },
  methods: {
    ...mapActions({
      signOut: AuthActions.LOGOUT,
    }),

    handleLogout() {
      this.signOut().then(() => this.$router.push({ name: 'sign-in' }));
    },
  },
});
