export enum Roles {
  TENANT = 'tenant',
  PRINCIPAL = 'principal',
  PM = 'property_manager',
  ADMIN = 'admin',
}

export interface User {
  id?: string;
  roles: Array<Roles>;

  email: string;
  password?: string;
  password_confirmation?: string;
  first_name?: string;
  last_name?: string;
  mobile_number: string;
  date_of_birth?: string;

  address_line?: string;
  state?: string;
  city?: string;
  country?: string;
  post_code?: string;

  abn?: string;
  acn?: string;

  profile_picture: string;
  avatar_src: string;

  business_name?: string;
  business_email_address?: string;
  business_website?: string;
  business_color?: string;
  business_text_color?: string;
  business_phone_number?: string;
  business_mobile_number?: string;

  verified_at?: string;
  skip_complete_profile?: string;
  profile_completed_at?: string;
  mobile_verified_at?: string;
  mobile_added_at?: string;
}
